import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import apexglow from "../../images/product/crbglow.png";
import crbapexrage from "../../images/product/apex3.jpg";
import apex_logo from "../../images/products__apex_logo.svg";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/catalog__apex.png";
import catalog__apex_image from "../../images/catalog__apex_image.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import useStatusStore from "../../store";
import Status from "../Status";
import AllGames from "./AllGames";
import Update from "../Status/Update/Update";

function Apex() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__apex_image}
        link="/catalog/apex"
        nav="Apex Legends"
        logo={catalog_game}
        title="Apex Legends"
        subtitle="2"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
          
          <AllGames
            link="/apex/crbglow"
            image={apexglow}
            title="CRB GLOW"
            logo={apex_logo}
            subtitle="Apex"
            status={<Status status={status.apex_glow} />}
            price="119"
          />
          <AllGames
            link="/apex/crrapexrage"
            image={crbapexrage}
            title="CRB Apex Rage"
            logo={apex_logo}
            subtitle="Apex"
            status={<Status status={status.apex_rage} />}
            price="299"
          />
        </div>
      </div>
    </section>
  );
}

export default Apex;
