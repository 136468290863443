import React from "react";
import "./Functional.css";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Description(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <p className="functional__description_subtitle">
      {props.name} {t("about.descriptionGame")} {props.game}.{t("about.descriptionGame2")}
    </p>
  );
}

export default Description;
