import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../i18next";

function AllGames(props) {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <section>
      <Link className="product__link" to={props.link}>
        <div className="product__wrap" style={{ backgroundImage: `url(${props.image})` }}>
          <div className="product__wrapper">
            {/* <img className="product__image" src={props.image} alt="apex" /> */}
            <div className="product__wrapper_width">
              <div className="product__wrapper_title-wrap">
                <p className="product__wrapper_title-text">{props.title}</p>
                <div className="product__wrapper_title-wrapper">
                  <img className="product__logo" src={props.logo} alt="apex" />
                  <p className="product__logo_title">{props.subtitle}</p>
                </div>
              </div>
              <div className="product__wrapper_product">
                <h3 className="product__wrapper_title">{t("about.status")}</h3>
                {props.status}
              </div>
              <div className="product__wrapper_price">
                <p className="product__wrapper_title">{t("about.price")}</p>
                <p className="product__wrapper_subtitle">
                  <span className="games__wrapper_subtitle-span">{t("about.from")}</span>{" "}
                  {props.price}₽
                </p>
              </div>
              <button className="product__button">{t("about.learnMore")}</button>
            </div>
          </div>
        </div>
      </Link>
    </section>
  );
}

export default AllGames;
