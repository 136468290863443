import React from "react";
import "../Status.css";

function Update() {
  return (
    <div className="update">
      {/* <div className="update__circle" /> */}
      <i className="fa fa-shield-check" style={{ marginRight: "5px" }}></i>
      <p className="update__text">On update</p>
    </div>
  );
}

export default Update;
