import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import bf2042_lite from "../../images/products__rust_lite.svg";
import bf2042_logo from "../../images/products__rust_logo.svg";
import Undetected from "../Status/Undetected/Undetected";
import Update from "../Status/Update/Update";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/Battlefield_2042_logo.png";
import catalog__bf2042_image from "../../images/catalog_bf2042.svg";

import AllGames from "./AllGames";

function Battlefield2042() {
  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__bf2042_image}
        link="/catalog/bf2042"
        nav="Battlefield 2042"
        logo={catalog_game}
        title="Battlefield 2042"
        subtitle="0"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
        
        </div>
      </div>
    </section>
  );
}

export default Battlefield2042;
