import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import rust_lite from "../../images/products__rust_lite.svg";
import rust_logo from "../../images/products__rust_logo.svg";
import rust_qc from "../../images/products__rust_qc.svg";
import Undetected from "../Status/Undetected/Undetected";
import Update from "../Status/Update/Update";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/catalog__rust.png";
import catalog__rust_image from "../../images/catalog__rust_image.svg";

import AllGames from "./AllGames";

function Rust() {
  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__rust_image}
        link="/catalog/rust"
        nav="Rust"
        logo={catalog_game}
        title="Rust"
        subtitle="0"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
        
        </div>
      </div>
    </section>
  );
}

export default Rust;
