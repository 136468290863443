import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import wot from "../../images/product/wot.png";
import wot_logo from "../../images/wot_white.png";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/wot_white.png";
import catalog__wot_image from "../../images/catalog__wot_image.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import useStatusStore from "../../store";
import Status from "../Status";
import AllGames from "./AllGames";

function Wot() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__wot_image}
        link="/catalog/wot"
        nav="WoT"
        logo={catalog_game}
        title="Wot"
        subtitle="2"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
          
          <AllGames
            link="/wot/lesta"
            image={wot}
            title="TanksBlitz"
            logo={wot_logo}
            subtitle="Wot"
            status={<Status status={status.wot_blitz} />}
            price="199"
          />
           <AllGames
            link="/wot/steam"
            image={wot}
            title="WotBlitz"
            logo={wot_logo}
            subtitle="Wot"
            status={<Status status={status.wot_blitz} />}
            price="199"
          />
        </div>
      </div>
    </section>
  );
}

export default Wot;
