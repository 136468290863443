import React from "react";
import "./Products.css";
import tarkov_logo from "../../images/products__tarkov_logo.svg";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";
import warface_logo from "../../images/warface_grey.svg";
import warface_meld from "../../images/products__warface_meld.svg";
import rust_logo from "../../images/products__rust_logo.svg";
import rust_qc from "../../images/products__rust_qc.svg";
import apex from "../../images/product/crbglow.png";

import AllGames from "../AllGames/AllGames";

import tarkov_viking from "../../images/products__tarkov_viking.svg";
import tarkov_shield from "../../images/products__tarkov_shield.svg";
import apex_logo from "../../images/products__apex_logo.svg";
import wot from "../../images/product/wot.png";
import netnease_logo from "../../images/netnease_logo.svg";
import bloodstrike from "../../images/product/bloodstrike.jpg";
import wot_logo from "../../images/wot_white.svg";
import warthunder from "../../images/product/warthunder_scr.png";
import warthunder_logo from "../../images/warthunder.png";
import { useTranslation } from "react-i18next";
import "../../i18next";
import useStatusStore from "../../store";
import Status from "../Status";
function Products() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="games">
      <div className="games__container">
        <div className="games__wrap_title">
          <h2 className="games__title">{t("about.popularCheats")}</h2>
          <Link className="games__link" to="/catalog">
            <button className="games__button_title">{t("about.viewCatalog")}</button>
          </Link>
        </div>

        <div className="games__wrap_grid">
          
        <AllGames
            link="/netease/bloodstrike"
            image={bloodstrike}
            title="BloodStrike"
            logo={netnease_logo}
            subtitle="Netease"
            status={<Status status={status.blood_strike} />}
            price="149"
          />
          <AllGames
            link="/wot/lesta"
            image={wot}
            title="TanksBlitz"
            logo={wot_logo}
            subtitle="Wot"
            status={<Status status={status.wot_blitz} />}
            price="199"
          />
           <AllGames
            link="/wot/steam"
            image={wot}
            title="WotBlitz"
            logo={wot_logo}
            subtitle="Wot"
            status={<Status status={status.wot_blitz} />}
            price="199"
          />
          <AllGames
            link="/apex/crbglow"
            image={apex}
            title="CRB GLOW"
            logo={apex_logo}
            subtitle="Apex"
            status={<Status status={status.apex_glow} />}
            price="119"
          />
            <AllGames
            link="/warthunder/CRBwar"
            image={warthunder}
            title="CRBWarThunder"
            logo={warthunder_logo}
            subtitle="WarThunder"
            status={<Status status={status.war_thunder} />}
            price="299"
          />

         
           
        </div>
      </div>
    </section>
  );
}

export default Products;
