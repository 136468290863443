import React from "react";
import "./Place.css";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Place() {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="place">
      <div className="place__container">
        <div className="place__wrap">
          <h1 className="place__title">{t("about.allCheats")}</h1>
          <Link className="place__link" to="/catalog">
            <button class="place__button">{t("about.catalog")}</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Place;
