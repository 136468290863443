import React from "react";
import "./NotFound.css";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <section className="notfound">
      <div className="notfound__container">
        <p className="notfound__title">404</p>
        <p className="notfound__subtitle">Page not found</p>
        <Link className="notfound__link" to="/">
          <button className="notfound__button">Go to HOME</button>
        </Link>
      </div>
    </section>
  );
}

export default NotFound;
