import React, { useState } from "react";
import "./Functional.css";
import { Link, NavLink } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CSSTransition as CSSTransition2 } from "react-transition-group";
import arrow_gray from "../../images/select__arrow_gray.svg";
import netnease_logo from "../../images/netnease_logo.svg";
import netnease from "../../images/neteasy.jpg";
import Undetected from "../Status/Undetected/Undetected";
import Risk from "../Status/Risk/Risk";
import Update from "../Status/Update/Update";
import esp from "../../images/esp.svg";
import aim from "../../images/aim.svg";
import loot from "../../images/loot.svg";
import misc from "../../images/misc.svg";
//import wot_media from "./../images/product/products_wot.jpg";
//import tarkov_media from '../../images/tarkov_media_abs.svg';
import os from "../../images/supported_os.svg";
import processors from "../../images/supported_processors.svg";
import rus from "../../images/flag__russia.png";
import usa from "../../images/flag__usa.png";

import Navigation from "./Navigation";
import Times2 from "./Times2";
import Description from "./Description";
import System from "./System";
import useStatusStore from "../../store";
import Status from "../Status";
import { useTranslation } from "react-i18next";
import "../../i18next";

function BloodStrike() {
  const [show, setShow] = useState("day");
  const status = useStatusStore((store) => store.status);
  const handleDayClick = () => {
    setShow("day");
  };
  const handleWeekClick = () => {
    setShow("week");
  };
  const handleMouthClick = () => {
    setShow("mouth");
  };

  const activeColor = "sub__button-active";

  const inactiveColor = "sub__button";

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="functional">
      <div className="functional__container">
        <Navigation
          link="/catalog/netease"
          title="NetEase"
          link2="/netease/bloodstrike"
          subtitle="BloodStrike"
        />

        <div className="functional__name_wrap">
          <h2 className="functional__title">BloodStrike</h2>
          <div className="functional__name_wrapper">
            <div className="functional__name_wrapper_title">
              <img className="functional__image" src={netnease_logo} alt="NetEase" />
              <p className="functional__text">NetEase</p>
            </div>
            <Status status={status.blood_strike} />
          </div>
          <p className="functional__description_title">{t("about.description")}</p>
          <Description name="CRB BloodStrike" game="BloodStrike" />
        </div>
      </div>
      {/* Info */}
      <div className="functional__info">
        <div className="functional__info_wrap">
          <h3 className="functional__info_title">{t("about.functional")}</h3>
          {/* Functionals */}
          <div className="functional__info_wrapper">
            
            <div className="functional__function_wrap">
              <div className="functional__function_wrapper">
                <img
                  className="functional__function_image"
                  src={misc}
                  alt="misc"
                />
                <h4 className="functional__function_title">MISC</h4>
              </div>
              <div className="functional__functions">
                 <button className="functional_function">No Recoil</button>
                <button className="functional_function">Crosshair mod</button>
                <button className="functional_function">Tp under map</button>
                <button className="functional_function">Magic Bullets</button>
                <button className="functional_function">Magic HEAD</button>
                <button className="functional_function">Mark</button>
                <button className="functional_function">Chams</button>
                <button className="functional_function">Gravity</button>
              </div>
            </div>
          </div>
        </div>

        {/* Media */}
        <div className="functional__media">
          <p className="functional__media_title">{t("about.media")}</p>

            {/*<img className="functional__media_logo" src="https://i.imgur.com/YiD7AuX.png" alt="wot" />*/}
            <iframe
            className="functional__video"
            id="urban"
            src="https://kinescope.io/embed/4c2gMS6hpTtu7ye45dHChN"
            allowfullscreen
          />
            
        </div>
      </div>
      {/* System */}
      <System win="Windows 10" win2="Windows 11" proc="Intel" proc2="AMD" />
      {/* Subscription plans */}
      <div className="sub__container">
        <p className="sub__title">{t("about.plans")}</p>
        <div className="sub__wrap_buttons">
          <button
            className={show === "day" ? activeColor : inactiveColor}
            onClick={handleDayClick}
          >
            1 day
          </button>
          <button
            className={show === "week" ? activeColor : inactiveColor}
            onClick={handleWeekClick}
          >
            7 day
          </button>
          <button
            className={show === "mouth" ? activeColor : inactiveColor}
            onClick={handleMouthClick}
          >
            30 day
          </button>
        </div>
        {/* 1 day */}
        {show === "day" && (
          <Times2
            link="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857355&lang=ru-RU"
            link2="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857355&lang=ru-RU"
            price="149"
            price2="1,45"
          />
        )}
        {/* 7 days */}
        {show === "week" && (
          <Times2
            link="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857358&lang=ru-RU"
            link2="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857358&lang=ru-RU"
            price="999"
            price2="9,72"
          />
        )}
        {/* 30 days */}
        {show === "mouth" && (
          <Times2
            link="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857360&lang=ru-RU"
            link2="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4857360&lang=ru-RU"
            price="1499"
            price2="14,58"
          />
        )}
      </div>
    </section>
  );
}

export default BloodStrike;
