// Main Elements
import { React, Suspense, useEffect } from "react";
import useStatusStore from "../../store";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Header from "../Header/Header";
import Main from "../Main/Main";
import Games from "../Games/Games";
import Products from "../Products/Products";
import Place from "../Place/Place";
import Catalog from "../Catalog/Catalog";
import Footer from "../Footer/Footer";
import Faq from "../Faq/Faq";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

// Games in Catalog
//import Tarkov from "../AllGames/Tarkov";
//import Valorant from "../AllGames/Valorant";
//import Fortnite from "../AllGames/Fortnite";
import Rust from "../AllGames/Rust";
import Apex from "../AllGames/Apex";
import Other from "../AllGames/Other";
//import Rainbow from "../AllGames/Rainbow";
//import Dayz from "../AllGames/Dayz";
//import Scum from "../AllGames/Scum";
//import Pubg from "../AllGames/Pubg";
//import Bloodhunt from "../AllGames/Bloodhunt";
//import Warface from "../AllGames/Warface";
//import Warzone from "../AllGames/Warzone";
import Wot from "../AllGames/Wot";
import WarThunder from "../AllGames/WarThunder";
import Battlefield2042 from "../AllGames/Battlefield2042";
// Games Functional
import WotLestagames from "../Functional/WotLesta";
import CERBERUSApex from "../Functional/CERBERUSApex";
import CRBApexRage from "../Functional/CRBApexRage";
import WotSteam from "../Functional/WotSteam";
import BloodStrike from "../Functional/BloodStrike";
import CRBwarthunder from "../Functional/CRBwarthunder";
import NotFound from "../NotFound/NotFound";

import { YMInitializer } from "react-yandex-metrika";
import CheatStatus from "../CheatStatus/CheatStatus";
import NetEasy from "../AllGames/NetEasy";

function App() {
  const setGamesStatus = useStatusStore((state) => state.setGamesStatus);

  useEffect(() => {
    async function getStatus() {
      await fetch(`${process.env.REACT_APP_API_URL}/status`)
        .then(async (res) => setGamesStatus(await res.json()))
        .catch((err) => console.log(err));
    }

    getStatus();
  }, []);

  return (
    <div className="page">
      <div>
        <YMInitializer accounts={[88153518]} />
      </div>
      <Suspense fallback="loading..">
        <ScrollToTop>
          <Switch>
            {/* Main */}
            <Route path="/" exact>
              <Header />
              <Main />
              <Games />
              <Products />
              <Place />
              <Footer />
            </Route>
            {/* Catalog */}
            <Route path="/catalog" exact>
              <Header />
              <Catalog />
              <Footer />
            </Route>
            {/* Faq */}
            <Route path="/cheat-status" exact>
              <Header />
              <CheatStatus />
              <Footer />
            </Route>
            {/* Cheat Status */}
            <Route path="/faq" exact>
              <Header />
              <Faq />
              <Footer />
            </Route>
            {/* other */}
            <Route path="/catalog/other" exact>
              <Header />
              <Other />
              <Footer />
            </Route>
            {/* Tarkov */}
            <Route path="/catalog/netease" exact>
              <Header />
              <NetEasy />
              <Footer />
            </Route>
            {/* BF2042 */}
            <Route path="/catalog/bf2042" exact>
              <Header />
              <Battlefield2042 />
              <Footer />
            </Route>
            {/* Wot */}
            <Route path="/catalog/wot" exact>
              <Header />
              <Wot />
              <Footer />
            </Route>
            {/* WarThunder */}
            <Route path="/catalog/warthunder" exact>
              <Header />
              <WarThunder />
              <Footer />
            </Route>
            {/* Valorant */}

            {/* Fortnite */}

            {/* Rust */}
            <Route path="/catalog/rust" exact>
              <Header />
              <Rust />
              <Footer />
            </Route>
            {/* Warzone */}

            {/* Apex */}
            <Route path="/catalog/apex" exact>
              <Header />
              <Apex />
              <Footer />
            </Route>

            {/* Rainbow */}
            
           
            {/* Dayz */}

            {/* Scum */}

            {/* Pubg */}

            {/* Bloodhunt */}

            {/* Warface */}

            {/* Games functional */}
            {/* Tarkov */}

            {/* Wot */}
            <Route path="/wot/lesta" exact>
              <Header />
              <WotLestagames />
              <Footer />
            </Route>
            <Route path="/wot/steam" exact>
              <Header />
              <WotSteam />
              <Footer />
            </Route>
            {/* WarThunder */}
            <Route path="/warthunder/CRBwar" exact>
              <Header />
              <CRBwarthunder />
              <Footer />
            </Route>
            {/* Valorant */}

            {/* Fortnite */}

            {/* Rust */}
            <Route path="/netease/bloodstrike" exact>
              <Header />
              <BloodStrike />
              <Footer />
            </Route>
            {/* Apex */}
            <Route path="/apex/crbglow" exact>
              <Header />
              <CERBERUSApex />
              <Footer />
            </Route>
            <Route path="/apex/crrapexrage" exact>
              <Header />
              <CRBApexRage />
              <Footer />
            </Route>
            {/* Spoofers */}

            {/* Pubg */}

            {/* Bloodhunt */}
            {/*{
            <Route path="/bloodhunt/bcware" exact>
              <Header />
              <BcwareBH />
              <Footer />
            </Route>
          }
          <Route path="/bloodhunt/millex" exact>
            <Header />
            <MillexBH />
            <Footer />
          </Route>*/}
            {/* Warface */}

            {/* Warzone */}

            {/* Rainbow */}

            {/* NotFound */}
            <Route path="*" exact>
              <Header />
              <NotFound />
              <Footer />
            </Route>
          </Switch>
        </ScrollToTop>
      </Suspense>
    </div>
  );
}

export default App;
