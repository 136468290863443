import React, { useState } from "react";
import "./Functional.css";
import { Link, NavLink } from "react-router-dom";
import arrow_gray from "../../images/select__arrow_gray.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";

function Navigation(props) {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <div>
      <div className="functional__navigation">
        <Link className="functional__link" to="/">
          <p className="functional__navigation_text">{t("about.home")}</p>
        </Link>
        <img className="functional__arrow_gray" src={arrow_gray} alt="arrow" />
        <Link className="functional__link" to="/catalog">
          <p className="functional__navigation_text">{t("about.catalog")}</p>
        </Link>
        <img className="functional__arrow_gray" src={arrow_gray} alt="arrow" />
        <Link className="functional__link" to={props.link}>
          <p className="functional__navigation_text">{props.title}</p>
        </Link>
        <img className="functional__arrow_gray" src={arrow_gray} alt="arrow" />
        <Link className="functional__link" to={props.link2}>
          <p className="functional__navigation_text-active">{props.subtitle}</p>
        </Link>
      </div>
    </div>
  );
}

export default Navigation;
