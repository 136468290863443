import React from "react";
import "./Product.css";
import Buttons from "../Catalog/Buttons";
import bloodstrike from "../../images/product/bloodstrike.jpg";
import bloodstrike_logo from "../../images/netnease_logo.svg";
import { Link } from "react-router-dom";
import Undetected from "../Status/Undetected/Undetected";

import CatalogTitle from "../Catalog/CatalogTitle";
import catalog_game from "../../images/netnease_logo.svg";
import catalog__neteasy_image from "../../images/neteasycat.svg";

import { useTranslation } from "react-i18next";
import "../../i18next";
import useStatusStore from "../../store";
import Status from "../Status";
import AllGames from "./AllGames";

function NetEasy() {
  const status = useStatusStore((store) => store.status);
  const { t, i18n } = useTranslation();
  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  }

  return (
    <section className="product">
      <CatalogTitle
        backgroundImage={catalog__neteasy_image}
        link="/catalog/netease"
        nav="NetEasy"
        logo={catalog_game}
        title="NetEasy"
        subtitle="1"
      />
      <div className="product__container">
        <Buttons />

        <div className="product__wrap_grid">
          
          <AllGames
            link="/netease/bloodstrike"
            image={bloodstrike}
            title="BloodStrike"
            logo={bloodstrike_logo}
            subtitle="NetEase"
            status={<Status status={status.blood_strike} />}
            price="149"
          />
        </div>
      </div>
    </section>
  );
}

export default NetEasy;
