import Undetected from "./Undetected/Undetected";
import Update from "./Update/Update";
import Risk from "./Risk/Risk";

export default function Status({ status }) {
  switch (status) {
    case "Undetected": {
      return <Undetected />;
    }
    case "Risk": {
      return <Risk />;
    }
    case "On Update": {
      return <Update />;
    }
    default: {
      return "Loading...";
    }
  }
}
